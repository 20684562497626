<template>
  <div class="all">
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="join-us-banner solution-header-container">
      <div class="solution-header-text" style="margin-top: -80px">
        <h1 data-caption-delay="0" class="wow fadeInLeft">年少有为 智创未来</h1>
        <p data-caption-delay="100" class="wow fadeInRight">真诚的欢迎您加入我们</p>
      </div>
    </div>
    <div class="container wow fadeInRight">
      <el-row>
        <el-col :span="3">&nbsp;</el-col>

        <el-col :span="6" :class="socialRecruitmentStyle">
          <div class="social-div" :class="activeType === 1 && 'active'" @click="selectByType(1)">
            <img src="../../assets/images/social-recruitment1.svg" class="recruitment-icon" alt=""/>
            <div class="recruitment-classical-chinese">社会招聘</div>
          </div>
        </el-col>
        <el-col :span="6" :class="campusRecruitmentStyle">
          <div class="social-div campus-div" :class="activeType === 2 && 'active'" @click="selectByType(2)">
            <img src="../../assets/images/campus-recruitment.png" class="recruitment-icon" style="margin-top: 5px"
                 alt=""/>
            <div class="recruitment-classical-chinese">校园招聘</div>
          </div>
        </el-col>
        <el-col :span="6" :class="internshipPositionsStyle">
          <div class="social-div" :class="activeType === 3 && 'active'" @click="selectByType(3)">
            <img src="../../assets/images/internship-positions.png" class="recruitment-icon" style="margin-top: 8px"
                 alt=""/>
            <div class="recruitment-classical-chinese">实习岗位</div>
          </div>
        </el-col>
        <el-col :span="3">&nbsp;</el-col>
      </el-row>
    </div>
    <img src="../../assets/images/line-for-join-us.png" alt="" class="line-image"/>
    <div class="container wow fadeInLeft">
      <el-row :span="24" class="select-input">
        <el-input placeholder="搜索职位" v-model="searchInput">
          <el-button slot="append" size="mini" class="select-button" @click="search">搜索</el-button>
        </el-input>
      </el-row>
      <div class="select">
        <el-row :span="24" class="search-criteria">
          <p class="content theme">工作地点：</p>
          <p :class="wholeStyle" @click="searchPlace('全部')">全部</p>
          <p :class="shenYangStyle" @click="searchPlace('沈阳')">沈阳</p>
          <p :class="wuHanStyle" @click="searchPlace('武汉')">武汉</p>
          <p :class="beiJingStyle" @click="searchPlace('北京')">北京</p>
          <p :class="shangHaiStyle" @click="searchPlace('上海')">上海</p>
          <p :class="tianJinStyle" @click="searchPlace('天津')">天津</p>
          <p :class="guangZhoStyle" @click="searchPlace('广州')">广州</p>
          <p :class="chongQingStyle" @click="searchPlace('重庆')">重庆</p>
        </el-row>
        <el-row :span="24" class="search-criteria">
          <p class="content theme">岗位分类：</p>
          <p :class="wholeStyle1" @click="searchPost('全部')">全部</p>
          <p :class="technologyStyle" @click="searchPost('研发技术')">研发技术</p>
          <p :class="marketingStyle" @click="searchPost('市场销售')">市场销售</p>
          <p :class="professionalFunctionsStyle" @click="searchPost('专业职能')">专业职能</p>
        </el-row>
      </div>
      <el-row :span="24" class="detailed-content">
        <div class="" v-if="list.length === 0">
          <el-empty :image-size="200"></el-empty>
        </div>
        <div v-for="item in list" :key="item.id" class="collapse-div wow fadeInLeft" v-else>
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item :name="item.id">
              <template slot="title">
                <div class="theme">
                  <div class="jobTitle">{{ item.jobTitle }}</div>
                  <div class="JobType">岗位分类：<span>{{ item.jobType }}</span></div>
                  <div class="jobAddress">工作地点：<span>{{ item.jobAddress }}</span></div>
                </div>
              </template>
              <div class="context">
                <el-row class="details">
                  <el-col :span="12" class="responsibilities">
                    <div class="">岗位职责:</div>
                    <div class="responsibilities-context">
                      <ol class="requirement-list">
                        <li
                            v-if="item.jobResponsibilities1 !== null && item.jobResponsibilities1 !== ''">
                          {{ item.jobResponsibilities1 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities2 !== null && item.jobResponsibilities2 !== ''">
                          {{ item.jobResponsibilities2 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities3 !== null && item.jobResponsibilities3 !== ''">
                          {{ item.jobResponsibilities3 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities4 !== null && item.jobResponsibilities4 !== ''">
                          {{ item.jobResponsibilities4 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities5 !== null && item.jobResponsibilities5 !== ''">
                          {{ item.jobResponsibilities5 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities6 !== null && item.jobResponsibilities6 !== ''">
                          {{ item.jobResponsibilities6 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities7 !== null && item.jobResponsibilities7 !== ''">
                          {{ item.jobResponsibilities7 }}
                        </li>
                        <li
                            v-if="item.jobResponsibilities8 !== null && item.jobResponsibilities8 !== ''">
                          {{ item.jobResponsibilities8 }}
                        </li>
                      </ol>
                    </div>
                  </el-col>
                  <el-col :span="12" class="requirements">
                    <div class="">任职要求:<br/></div>
                    <div class="requirements-context">
                      <ol class="requirement-list">
                        <li
                            v-if="item.jobRequirements1 !== null && item.jobRequirements1 !== ''">
                          {{ item.jobRequirements1 }}
                        </li>
                        <li
                            v-if="item.jobRequirements2 !== null && item.jobRequirements2 !== ''">
                          {{ item.jobRequirements2 }}
                        </li>
                        <li
                            v-if="item.jobRequirements3 !== null && item.jobRequirements3 !== ''">
                          {{ item.jobRequirements3 }}
                        </li>
                        <li
                            v-if="item.jobRequirements4 !== null && item.jobRequirements4 !== ''">
                          {{ item.jobRequirements4 }}
                        </li>
                        <li
                            v-if="item.jobRequirements5 !== null && item.jobRequirements5 !== ''">
                          {{ item.jobRequirements5 }}
                        </li>
                        <li
                            v-if="item.jobRequirements6 !== null && item.jobRequirements6 !== ''">
                          {{ item.jobRequirements6 }}
                        </li>
                        <li
                            v-if="item.jobRequirements7 !== null && item.jobRequirements7 !== ''">
                          {{ item.jobRequirements7 }}
                        </li>
                        <li
                            v-if="item.jobRequirements8 !== null && item.jobRequirements8 !== ''">
                          {{ item.jobRequirements8 }}
                        </li>
                      </ol>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <el-row :span="24" class="apply">
                <el-button class="apply-button" @click="applyNow(item.type)"><span class="gradient-text">立即申请</span>
                </el-button>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-row>
    </div>
    <div class="resume-submission wow fadeInUp">
      <div class="container">
        <div class="submission-title">
          <img src="../../assets/images/immediate-delivery.png" class="submission-image" alt="">
          <span class="submission-context">简历投递方式</span>
        </div>
        <div class="recruitment-details">
          <el-row>
            <el-col :xs="16" :sm="16" :md="16" :lg="8" :xl="8">
              <span class="title">社会招聘</span>
              <div>
                <p class="context">扫描右侧二维码投递或发送简历至邮箱：<br/></p>
                <p class="context">jobs@dotrustech.com<br/></p>
                <p class="context">邮件标题格式：应聘岗位-姓名-城市<br/></p>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <img src="../../assets/images/QR-code.png" class="recruitment-image" alt="">
            </el-col>
            <el-col :xs="16" :sm="16" :md="16" :lg="8" :xl="8">
              <span class="title">校园/实习招聘</span>
              <div>
                <p class="context">扫描右侧二维码投递或发送简历至邮箱：<br/></p>
                <p class="context">hr@dotrustech.com<br/></p>
                <p class="context">邮件标题格式：应聘岗位-姓名-学校-城市<br/></p>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :md="8" :lg="4" :xl="4">
              <img src="../../assets/images/QR-code.png" class="recruitment-image" alt="">
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue";
import mainFooter from "@/components/MainFooter.vue";
import "@/style/joinUs.less"

export default {
  name: "joinUs",
  components: {mainFooter, mainNav},
  data() {
    return {
      activeIndex: '6',
      shenYangStyle: 'content specific',
      beiJingStyle: 'content specific',
      shangHaiStyle: 'content specific',
      wuHanStyle: 'content specific',
      tianJinStyle: 'content specific',
      guangZhoStyle: 'content specific',
      chongQingStyle: 'content specific',
      wholeStyle: 'content specific special',
      wholeStyle1: 'content specific special',
      technologyStyle: 'content specific type-of',
      marketingStyle: 'content specific type-of',
      professionalFunctionsStyle: 'content specific type-of',
      place: '',
      post: '',
      searchInput: '',
      socialRecruitmentStyle: 'career-type',
      campusRecruitmentStyle: 'career-type',
      internshipPositionsStyle: 'career-type',
      activeType: 1,
      // 当前职位
      list: [],
      // 社会招聘：type=1，校园招聘：type=1，实习岗位：type=1
      // 社会
      socialRecruitmentList: [{
        "id": "1",
        "jobTitle": "汽车功能测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉、重庆",
        "jobResponsibilities1": "分析解读功能规范，编辑测试用例；",
        "jobRequirements1": "统招本科及以上学历，计算机类、电子信息、通信、自动化、物理、数学等相关专业优先；",
        "jobResponsibilities2": "根据测试用例，执行功能测试；",
        "jobRequirements2": " 一年及以上测试工作经验；",
        "jobResponsibilities3": "分析产品功能，手动设计测试用例，提高测试覆盖率和测试质量；",
        "jobRequirements3": " 有汽车电子开发与测试工作经验者优先；",
        "jobResponsibilities4": "负责测试环境的搭建和维护；",
        "jobRequirements4": " 熟悉CAN(FD)\\LIN\\Ethernet等车载总线功能者优先； ",
        "jobResponsibilities5": "编写测试报告，并对问题进行描述、分析、定位及跟踪；",
        "jobRequirements5": " 熟悉Vector工具，如CANoe、CANalyzer等测试工具者优先；",
        "jobResponsibilities6": "负责向客户提供必要的技术支持和培训；",
        "jobRequirements6": " 熟悉测试用例编写；",
        "jobResponsibilities7": "负责组织解决测试中的技术难题，提出解决方案并实施；",
        "jobRequirements7": "拥有一年及以上汽车驾驶经验优先；",
        "jobResponsibilities8": null,
        "jobRequirements8": "能接受驻场出差。",
        "type": 1
      }, {
        "id": "2",
        "jobTitle": "汽车诊断测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉、重庆",
        "jobResponsibilities1": "分析解读汽车诊断相关标准或规范，编写测试规范；",
        "jobRequirements1": "统招本科及以上学历，计算机类、电子信息、通信、自动化、物理、数学等相关专业优先；",
        "jobResponsibilities2": "根据测试规范，执行CAN(FD)或Ethernet诊断相关测试；",
        "jobRequirements2": " 一年及以上测试工作经验；",
        "jobResponsibilities3": "修改或维护诊断自动化测试脚本，完成自动化测试；",
        "jobRequirements3": " 熟悉诊断相关标准（ISO14229/ISO15765）；",
        "jobResponsibilities4": "负责测试环境的搭建和维护；",
        "jobRequirements4": " 拥有汽车诊断开发或测试工作经验者优先；",
        "jobResponsibilities5": "编写测试报告，并对问题进行描述、分析、定位及跟踪；",
        "jobRequirements5": " 熟悉CAN(FD)\\LIN\\Ethernet等车载总线者优先；",
        "jobResponsibilities6": "负责向客户提供必要的技术支持和培训；",
        "jobRequirements6": " 熟悉CAPL、C++、C#、Python等至少一种编程语言者优先；",
        "jobResponsibilities7": "负责组织解决测试中的技术难题，提出解决方案并实施；",
        "jobRequirements7": " 熟悉Vector工具，如CANoe、VT System、CAN、Diva等测试工具者优先；",
        "jobResponsibilities8": null,
        "jobRequirements8": "能接受驻场出差。",
        "type": 1
      }, {
        "id": "3",
        "jobTitle": "汽车网络测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉、重庆",
        "jobResponsibilities1": "分析解读汽车网络相关标准或规范，编写测试规范；",
        "jobRequirements1": "统招本科及以上学历，计算机类、电子信息、通信、自动化、物理、数学等相关专业优先；",
        "jobResponsibilities2": "根据测试规范，执行CAN(FD)/LIN/Ethernet等总线相关测试；",
        "jobRequirements2": " 一年及以上测试工作经验；",
        "jobResponsibilities3": "修改或维护自动化测试脚本，完成网络自动化测试；",
        "jobRequirements3": " 有汽车电子开发或测试工作经验者优先；",
        "jobResponsibilities4": "负责测试环境的搭建和维护；",
        "jobRequirements4": " 熟悉CAN(FD)\\LIN\\Ethernet等车载总线通讯协议者优先；",
        "jobResponsibilities5": "编写测试报告，并对问题进行描述、分析、定位及跟踪；",
        "jobRequirements5": " 熟悉CAPL、C++、C#、Python等至少一种编程语言者优先；",
        "jobResponsibilities6": "负责向客户提供必要的技术支持和培训；",
        "jobRequirements6": " 熟悉Vector工具，如CANoe、VT System、Vteststudio等测试工具者优先；",
        "jobResponsibilities7": "负责组织解决测试中的技术难题，提出解决方案并实施；",
        "jobRequirements7": "拥有一年及以上汽车驾驶经验者优先；",
        "jobResponsibilities8": null,
        "jobRequirements8": "能接受驻场出差。",
        "type": 1
      }, {
        "id": "4",
        "jobTitle": "整车电子电气架构工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责汽车电子电气架构项目客户需求分析，根据整车功能需求、法规和平台化要求，分析电子电气整车需求，整理UseCase，撰写需求报告及解决方案；",
        "jobRequirements1": "统招硕士及以上学历，车辆工程、电子信息、通信工程、自动化等相关专业优先；",
        "jobResponsibilities2": "负责需求模型的建模及管理（如需求追踪、变更管理、权限管理、生命周期管理等），参与技术评审工作（包括需求评审、系统方案设计评审、详细设计评审等）；",
        "jobRequirements2": "具有整车电子电气系统及零部件或整车线束系统设计开发/CAN、LIN总线系统设计开发/汽车以太网开发任一方面经验优先；",
        "jobResponsibilities3": "负责汽车电子电气架构设计开发及管理平台PREEvision的应用支持及二次开发；负责进行架构系统开发及模型建模，参与系统方案的讨论、SSTS及CTS设计评审，基于PREEvision工具进行功能定制及二次开发；",
        "jobRequirements3": "熟悉CAN/LIN总线系统的规范，熟悉总线协议（包括ISO1189ISO1576ISO14229、LIN2.0等），会使用总线仿真及开发工具者优先（包括CANoe、CANdela Studio等）；",
        "jobResponsibilities4": "负责汽车总线设计：负责整车网络架构设计、网络规范制定、负责整车通信矩阵、通信数据库及诊断数据库设计；",
        "jobRequirements4": "熟悉以太网通讯原理，掌握以太网相关知识者优先，TCP/IP、Switch、VLAN、SOME/IP、DoIP、AVB/TSN协议等；",
        "jobResponsibilities5": "负责汽车以太网设计：负责车载以太网技术的研究、负责汽车以太网网络规范制定、负责汽车以太网网络通讯矩阵、网络通信数据库设计；",
        "jobRequirements5": "了解汽车电子电气架构开发工具PREEvision、DOORS等软件者优先；",
        "jobResponsibilities6": "负责对客户进行项目实施培训、并指导后续的实施应用。",
        "jobRequirements6": "对新技术具有强烈的兴趣，具备系统的学习和分析能力；",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "5",
        "jobTitle": "汽车网络架构工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责Ether/CAN/CANFD/LIN总线协议解读、开发，规范制定、通信设计；",
        "jobRequirements1": "统招硕士及以上学历，车辆工程、计算机、电子、通信、自动化等相关专业优先；",
        "jobResponsibilities2": "负责进行架构系统开发（包括硬件架构、原理设计，拓扑设计等）",
        "jobRequirements2": "具备汽车行业经验，如线束开发、测试、零部件开发、车载网络开发经验者优先；",
        "jobResponsibilities3": "负责整车网络管理、能量管理、电源分配等；",
        "jobRequirements3": "对整车网络设计及功能定义等汽车电子电气架构开发等研发类工作感兴趣；",
        "jobResponsibilities4": "负责诊断方案开发、诊断设计，制作零部件和整车级诊断数据库，负责生产及售后诊断方案设计等；",
        "jobRequirements4": "耐心细致，有责任心，有较强的团队意识；",
        "jobResponsibilities5": "负责网络设计及参数配置，生成通信数据库DBC/LDF/ARXML；",
        "jobRequirements5": "能接受出差。",
        "jobResponsibilities6": "负责网络开发过程中问题分析跟踪、解决验证；",
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "6",
        "jobTitle": "嵌入式软件工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、重庆",
        "jobResponsibilities1": " 负责车载控制器中间件协议栈软件的设计与开发；",
        "jobRequirements1": " 统招硕士及以上学历，计算机类、通信、自动化等电子类相关专业优先；",
        "jobResponsibilities2": " 负责项目与产品开发过程中相关文档的编写；",
        "jobRequirements2": " 两年及以上嵌入式软件开发经验，熟悉C/C++，有汽车电子行业经验优先；",
        "jobResponsibilities3": " 参与方案讨论及解决开发中的技术难题；",
        "jobRequirements3": " 熟练使用嵌入式软件开发、下载工具，至少掌握Keil、IAR或CodeWarrior中的一种；",
        "jobResponsibilities4": null,
        "jobRequirements4": " 学习领悟能力强，具备高度的责任心及团结协作精神，善于沟通交流；",
        "jobResponsibilities5": null,
        "jobRequirements5": "能接受出差。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "7",
        "jobTitle": "应用开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责公司产品及代理产品的技术深耕及配套技术开发；",
        "jobRequirements1": "统招硕士及以上学历，车辆工程、计算机、信息电子、自动化等相关专业优先；",
        "jobResponsibilities2": "负责总线通讯及测试、诊断等技术深耕及应用；",
        "jobRequirements2": "掌握C或C++等一项编程语言开发技术，并具有一定开发QT的经验，其开发经验不限于QT或是PyQT，具有上位机软件开发经验者优先；",
        "jobResponsibilities3": null,
        "jobRequirements3": "熟悉汽车相关通信协议（CAN/CANFD,LIN，J1939，Ethernet等总线协议其中一项）；",
        "jobResponsibilities4": null,
        "jobRequirements4": "备注：应聘者应具备上述任职资格两项。",
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "8",
        "jobTitle": "Java开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "从事汽车电子业务方向所需工具和产品的设计开发工作，及相关软件技术服务工作；",
        "jobRequirements1": "统招本科及以上学历，计算机、软件工程专业，汽车电子行业或具备相关知识优先；",
        "jobResponsibilities2": "基于产品需求或项目需求进行软件设计和编码实现，满足功能、质量和性能要求；",
        "jobRequirements2": "2年及以上Java计算机软件相关从业经验；",
        "jobResponsibilities3": "参与测试、部署、实施等关联技术工作；",
        "jobRequirements3": "熟练掌握Java开发语言，掌握Spring MVC, Spring Boot等常用开发框架；",
        "jobResponsibilities4": "分析并解决软件开发过程中的技术和业务问题；",
        "jobRequirements4": "熟悉主流数据库，熟练掌握SQL；",
        "jobResponsibilities5": null,
        "jobRequirements5": "掌握vue、jquery等前端技术，具有相关开发经验者优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "具有阅读相关技术需求文档能力；具有一定的软件设计及文档编写能力；",
        "jobResponsibilities7": null,
        "jobRequirements7": "有较强的学习能力，具有良好的表达能力、理解能力和交流能力，良好的团队合作精神和敬业精神，富有进取心。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "9",
        "jobTitle": "C#开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉",
        "jobResponsibilities1": "从事汽车电子业务方向所需工具和产品的Windows桌面工具或服务的开发，及相关软件技术服务工作;",
        "jobRequirements1": "统招本科及以上学历，计算机相关专业；有汽车业务方向工作经验者优先;",
        "jobResponsibilities2": "基于产品需求或项目需求进行软件设计和编码实现，满足功能、质量和性能要求;",
        "jobRequirements2": "二年以上c#、wpf技术方向设计和开发工作经验；掌握基本的xaml和style样式及模版;",
        "jobResponsibilities3": "参与测试、部署、实施等关联技术工作;",
        "jobRequirements3": "精通c#及面向对象设计开发、设计模式，对sqlite数据库、多线程、文本解析、excel解析有比较好的理解;",
        "jobResponsibilities4": null,
        "jobRequirements4": "具备基本的根据厂家提供的说明文档能有基本的封装控制能力;",
        "jobResponsibilities5": null,
        "jobRequirements5": "具有较强技术文档和方案文档编写能力；",
        "jobResponsibilities6": null,
        "jobRequirements6": "具有较强的研究能力、理解能力、表达能力和交流能力，擅于团队合作，富有敬业精神和进取心。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "10",
        "jobTitle": "软件测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉",
        "jobResponsibilities1": "从事公司内自研软件工具及产品的测试工作；",
        "jobRequirements1": " 统招本科及以上学历，计算机相关专业，有汽车业务方向工作经验者优先；",
        "jobResponsibilities2": "与不同团队协同合作，从项目维度进行管理，提供软件测试服务；",
        "jobRequirements2": " 掌握C/C++/JAVA/Python至少一种语言的编程基础；",
        "jobResponsibilities3": "配合开发团队完成需求分析，负责测试用例设计、测试编码等；",
        "jobRequirements3": " 具备自动化测试工具开发或脚本程序编写经验；",
        "jobResponsibilities4": "独立创建软件验证规范、定义测试方法；",
        "jobRequirements4": " 具备三年以上软件黑盒及白盒测试经验，较强技术文档和方案文档编写能力；",
        "jobResponsibilities5": "独立搭建自动化测试环境；",
        "jobRequirements5": " 具有较强的研究能力、理解能力、表达能力和交流能力；",
        "jobResponsibilities6": null,
        "jobRequirements6": " 擅于团队合作，富有敬业精神和进取心。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "11",
        "jobTitle": "动力系统仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责动力系统仿真测试方案的制定；",
        "jobRequirements1": "统招本科及以上学历，车辆、电子、自动化、控制等专业优先；",
        "jobResponsibilities2": null,
        "jobRequirements2": "熟练掌握MATLAB/Simulink工具，有被控对象模型开发经验者优先 ;",
        "jobResponsibilities3": null,
        "jobRequirements3": "有Carmaker、Carsim、Dynasimulink仿真建模经验者优先考虑。",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉传统动力及新能源三电系统者优先。",
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "12",
        "jobTitle": "底盘系统仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责底盘系统仿真测试的实施，车辆模型参数化及调试；",
        "jobRequirements1": "统招本科及以上学历，车辆、电子、自动化、控制等专业优先；",
        "jobResponsibilities2": null,
        "jobRequirements2": "熟练掌握MATLAB/Simulink工具，有被控对象模型开发经验者优先 ;",
        "jobResponsibilities3": null,
        "jobRequirements3": "有NI PXI、dSPACE、IPG、ETAS LABCAR等软硬件产品使用经验者优先；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉底盘系统仿真建模者优先。",
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "13",
        "jobTitle": "HIL仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京、武汉",
        "jobResponsibilities1": "负责HIL测试系统对象模型的建立及HIL测试系统的实现；",
        "jobRequirements1": "统招本科及以上学历，电气，自动化，控制等相关专业优先；",
        "jobResponsibilities2": "负责HIL测试系统测试用例设计和实施；",
        "jobRequirements2": "有NI PXI、dSPACE、IPG、CANOE、ETAS LABCAR等平台测试软件使用经验者优先考虑；",
        "jobResponsibilities3": null,
        "jobRequirements3": "能接受出差。",
        "jobResponsibilities4": null,
        "jobRequirements4": null,
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "14",
        "jobTitle": "自动驾驶仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京、武汉",
        "jobResponsibilities1": "根据项目需求，定制针对于自动驾驶系统的基于模型的自动化测试验证平台；",
        "jobRequirements1": "统招本科及以上学历，车辆工程、计算机、通讯、电子及自动化等相关专业优先；",
        "jobResponsibilities2": "基于模型的方法进行控制软件验证、测试；",
        "jobRequirements2": "对基于模型的开发有浓厚的兴趣，对自动驾驶系统验证感兴趣，熟悉控制器设计、开发和仿真；",
        "jobResponsibilities3": "负责自动驾驶MIL/SIL/HIL测试系统对象模型的建立以及测试系统的实现；",
        "jobRequirements3": "有嵌入式电子产品实际研发经验的优先考虑；",
        "jobResponsibilities4": null,
        "jobRequirements4": "有NI PXI、dSPACE、RT-Lab、ETAS LabCar等平台应用经验的优先考虑；",
        "jobResponsibilities5": null,
        "jobRequirements5": "熟悉Simulink等模型开发工具优先考虑；",
        "jobResponsibilities6": null,
        "jobRequirements6": "有较好的抗压能力；",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "15",
        "jobTitle": "自动驾驶仿真开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京、武汉",
        "jobResponsibilities1": "   根据项目应用需求，负责仿真软件相关二次开发；",
        "jobRequirements1": "统招本科及以上学历，通讯、电子及自动化、计算机等相关专业优先；",
        "jobResponsibilities2": "   参与自动驾驶相关仿真系统搭建；",
        "jobRequirements2": "至少熟悉一种场景仿真平台，如VTD、PreSCAN、CarMaker、SCANeR等；",
        "jobResponsibilities3": "   负责仿真软件及与其他第三方软件的集成与调试；",
        "jobRequirements3": "熟悉c/c++开发，具有较强的性能优化意识；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉linux平台及Makefile；",
        "jobResponsibilities5": null,
        "jobRequirements5": "工作细致，责任心强，有良好的沟通协调能力和学习能力；",
        "jobResponsibilities6": null,
        "jobRequirements6": "能熟练阅读英文文档；",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "16",
        "jobTitle": "自动驾驶仿真系统工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京、武汉",
        "jobResponsibilities1": "完成整个测试系统的设计、集成及激励测试；",
        "jobRequirements1": " 统招本科及以上学历，车辆工程、计算机、通讯、电子及自动化等相关专业；",
        "jobResponsibilities2": "对接开发工程师和测试工程师，将子系统信号流梳理完整，并协助调试； ",
        "jobRequirements2": "  对基于模型的开发有浓厚的兴趣，对自动驾驶系统验证感兴趣，熟悉控制器设计、开发和仿真；",
        "jobResponsibilities3": "对接硬件供应商，完成硬件产品的验证与技术支持；",
        "jobRequirements3": " 熟悉总线通讯CAN/LIN/以太网；",
        "jobResponsibilities4": "维护技术资料；",
        "jobRequirements4": " 有NI PXI、dSPACE、CANoe等平台应用经验的优先考虑；",
        "jobResponsibilities5": null,
        "jobRequirements5": " 熟悉Simulink等模型开发工具优先考虑；",
        "jobResponsibilities6": null,
        "jobRequirements6": " 有较好的抗压能力、能接受长期出差。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "17",
        "jobTitle": "智能座舱仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京",
        "jobResponsibilities1": "负责汽车智能座舱HIL系统的测试用例开发工作；",
        "jobRequirements1": "统招本科及以上学历，通信、车辆工程、自动化、电子、计算机等相关专业优先；",
        "jobResponsibilities2": "根据客户功能需求制定测试规范，设计测试用例，组织测试用例评审；",
        "jobRequirements2": "3年以上智舱域仪表或中控系统测试经验，掌握测试流程、熟悉测试用例设计方法；",
        "jobResponsibilities3": "参与信息娱乐HIL自动化测试脚本开发，按照测试计划执行测试任务，参与测试实施；",
        "jobRequirements3": "熟练使用CANoe、CANalyzer等相关工具，了解LabView等工具；",
        "jobResponsibilities4": "参与信息娱乐HIL测试系统搭建及调试；",
        "jobRequirements4": "具备测试脚本编写能力，具备Capl、C语言等编程能力；",
        "jobResponsibilities5": null,
        "jobRequirements5": "具备良好的团队合作和沟通能力，具备独立解决问题的能力；",
        "jobResponsibilities6": null,
        "jobRequirements6": "具备汽车电子基础专业知识，有仪表及中控相关开发及测试经验优先。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "18",
        "jobTitle": "智能座舱软件开发工程师",
        "jobType": "研发技术",
        "jobAddress": "北京",
        "jobResponsibilities1": " 负责汽车座舱自动化测试软件界面、调用接口、系统功能等开发工作；",
        "jobRequirements1": "统招本科及以上学历，通信、车辆工程、电气自动化、计算机等相关专业优先，具备3年以上相关工作经验； ",
        "jobResponsibilities2": " 负责自动化测试软件的升级与维护；",
        "jobRequirements2": " 具备良好的英文阅读能力；",
        "jobResponsibilities3": " 参与信息娱乐HIL自动化测试脚本开发，参与测试实施；",
        "jobRequirements3": " 了解LabView编程，精通C/C#/C++程序设计，具备一定的软件基本框架设计能力；",
        "jobResponsibilities4": " 参与信息娱乐HIL测试系统搭建及调试；",
        "jobRequirements4": " 熟悉Windows软件开发和数据库操作, 具有界面开发工作经验、上位机软件开发经验者优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": " 熟悉各个设备间常用通讯协议（如SCI、TCP/IP等），了解微机原理及接口技术；",
        "jobResponsibilities6": null,
        "jobRequirements6": " 具备良好的团队合作和沟通能力，具备独立解决问题的能力；",
        "jobResponsibilities7": null,
        "jobRequirements7": " 具备汽车电子基础专业知识，有仪表及HMI相关开发及测试经验优先。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "19",
        "jobTitle": "车身HIL测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责车身HIL自动化测试系统及台架搭建；",
        "jobRequirements1": "统招本科及以上学历，测控、汽车电子、机电、电子/电气、自动化、软件等专业优先；",
        "jobResponsibilities2": "负责编写测试计划、规划详细的测试方案；",
        "jobRequirements2": "具备基础的编程知识：C/C++/java/Python等均可；",
        "jobResponsibilities3": "负责MIL、HIL测试系统的测试用例设计及实施；",
        "jobRequirements3": "具备良好的沟通和协调能力，良好的英语阅读及翻译能力；",
        "jobResponsibilities4": "负责测试脚本调试，测试执行和记录，测试报告输出，测试问题跟踪；",
        "jobRequirements4": "主动性强，具备较强的学习能力以及团队配合能力；",
        "jobResponsibilities5": null,
        "jobRequirements5": "了解CAN/LIN/ETH总线协议优先、有使用CANoe、CANalyzer等软件经验优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "对整车功能及控制器功能逻辑有一定了解；",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "20",
        "jobTitle": "车载网络测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责车载网络协议测试规范的制定及测试实施，包含CAN/CANFD/LIN/FlexRay等；",
        "jobRequirements1": "统招本科及以上学历，通信、车辆工程、自动化、电子、计算机等相关专业优先；",
        "jobResponsibilities2": "负责测试用例及脚本代码的开发；",
        "jobRequirements2": "具有良好的编程能力（C、C++、Python等）；",
        "jobResponsibilities3": "负责部分控制器、集成系统和实车的现场测试工作；",
        "jobRequirements3": "具备良好的沟通和协调能力，良好的英语阅读及翻译能力；",
        "jobResponsibilities4": "负责跟客户沟通测试结果、分析原因及问题跟踪；",
        "jobRequirements4": "了解CAN、LIN、FlexRay网络协议，对OSEK及UDS诊断协议有一定了解的优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "熟悉Vector工具链的优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "有汽车控制器开发及测试项目经验者优先;",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "21",
        "jobTitle": "车载以太网测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责以太网络测试环境/系统的搭建；",
        "jobRequirements1": " 统招本科及以上学历，通信工程、车辆工程、自动化、电子、计算机等相关专业优先；",
        "jobResponsibilities2": "负责以太网测试规范的开发；",
        "jobRequirements2": " 具有良好沟通能力和英语阅读能力；",
        "jobResponsibilities3": "负责以太网自动化测试脚本的开发、编写；",
        "jobRequirements3": " 具有良好的编程能力（C、C++、C#、python等），可独立进行自动化脚本开发；",
        "jobResponsibilities4": "负责以太网测试执行及测试结果分析、测试报告整理；",
        "jobRequirements4": " 熟悉总线协议（CAN）和传统以太网协议（TCPIP）等相关知识优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": " 有Vector、Spirent相关产品使用经验优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": " 有示波器、矢量网络分析仪使用经验优先；",
        "jobResponsibilities7": null,
        "jobRequirements7": " 能接受出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "22",
        "jobTitle": "车载以太网专家工程师",
        "jobType": "研发技术",
        "jobAddress": "北京",
        "jobResponsibilities1": "负责车载以太网自动化测试系统的设计及开发工作；",
        "jobRequirements1": "具备5年及以上车载以太网开发或测试经验；",
        "jobResponsibilities2": "负责与客户对接，进行车载以太网测试需求分析及计划制定；",
        "jobRequirements2": "精通车载Ethernet、OPEN TCTSN、DDS多种网络通信协议基础原理；",
        "jobResponsibilities3": "负责组织进行系统集成及开发工作；",
        "jobRequirements3": "精通Vector或Spirent测试工具链产品功能；",
        "jobResponsibilities4": "负责部分自动化测试用例开发及调试工作；",
        "jobRequirements4": "精通车载以太网自动化测试原理知识；",
        "jobResponsibilities5": "负责部门业务售前推广工作；",
        "jobRequirements5": "有一定测试脚本开发能力，熟悉C/C++，C#，Python其一种编程语言；",
        "jobResponsibilities6": null,
        "jobRequirements6": "有汽车电子控制器通信软件模块开发经验者优先。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "23",
        "jobTitle": "V2X测试开发工程师",
        "jobType": "研发技术",
        "jobAddress": "北京",
        "jobResponsibilities1": "负责编写V2X车联网场景测试用例规范；",
        "jobRequirements1": "统招本科及以上学历，对V2X应用场景有认知基础；",
        "jobResponsibilities2": "负责开发V2X车联网场景测试用例脚本代码及调试；",
        "jobRequirements2": "掌握C/C++语言，具有CANoe CAPL环境开发经验者者优先；",
        "jobResponsibilities3": "针对行业内常规V2X仿真仪器仪表设备，例如R&S CMW500等进行联合开发调试，实现V2X仿真测试功能；",
        "jobRequirements3": "掌握C-V2X通信协议，有一定通信仿真仪表使用经验者优先；",
        "jobResponsibilities4": "解读并掌握TCSAE 53 2017/2020国标V2X应用场景标准；",
        "jobRequirements4": "需要掌握以太网TCP/IP协议，有CAN、LIN协议经验者优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "有责任心和团队意识及学习意愿、良好得沟通能力。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "24",
        "jobTitle": "汽车电子应用工程师",
        "jobType": "市场销售",
        "jobAddress": "武汉、重庆",
        "jobResponsibilities1": "配合区域销售团队，针对Vector等代理产品开展售前交流、售后培训及日常技术支持工作； ",
        "jobRequirements1": "统招本科及以上学历，车辆工程、电子、控制、自动化、计算机、通信等专业优先；",
        "jobResponsibilities2": "使用CAPL、C#、C++、Python等编程语言基于Vector产品进行二次开发；",
        "jobRequirements2": "掌握Labview、C、C++、C#、Python等至少一种编程语言，具备基础英文能力；",
        "jobResponsibilities3": "解析汽车网络相关协议标准(CAN XL、XCP/CCP、Ethernet等)；",
        "jobRequirements3": "熟悉CANoe、CANdelaStudio、CANape等Vector工具及汽车相关通信协议及标准优先；",
        "jobResponsibilities4": "参与CAN/LIN/Ethernet等汽车电子自动化测试项目；",
        "jobRequirements4": "具备较强的亲和力、自主学习意识、沟通协调能力及客户服务意识；",
        "jobResponsibilities5": null,
        "jobRequirements5": "能接受出差，出差地点主要位于珠三角、湘鄂皖等地。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }, {
        "id": "25",
        "jobTitle": "客户经理/销售工程师",
        "jobType": "市场销售",
        "jobAddress": "沈阳、北京、天津、武汉、广州、上海、重庆",
        "jobResponsibilities1": "负责公司代理产品的市场渠道开拓与销售工作；",
        "jobRequirements1": "统招本科及以上学历，车辆工程、电子、控制、机械、自动化等相关专业优先，对电控技术有一定的技术基础；",
        "jobResponsibilities2": "协助公司相关业务经理做好自主业务的销售工作；",
        "jobRequirements2": "良好的沟通协调能力、表达能力和工作热情；",
        "jobResponsibilities3": "掌握公司产品的功能和特性，可独立演示；",
        "jobRequirements3": "心理承受能力强、责任心强，性格活跃，喜欢销售工作，乐于与人交往；",
        "jobResponsibilities4": "与客户保持良好沟通，实时把握客户需求，为客户提供主动、热情、满意、周到的服务；",
        "jobRequirements4": "能接受出差；",
        "jobResponsibilities5": "根据公司产品、价格及市场策略，独立处置报价、合同条款的协商及合同签订等事宜，在执行合同过程中，协调并监督公司各职能部门操作；",
        "jobRequirements5": "具有汽车行业、汽车电子控制领域工作背景者优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "具有两年以上销售、售前售后技术支持工作经验者优先。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 1
      }],
      // 校园
      campusRecruitmentList: [{
        "id": "1",
        "jobTitle": "Qt开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "使用Qt进行上位机或智能座舱HMI方向软件开发；",
        "jobRequirements1": "统招本科及以上学历，计算机、通信、自动化等相关专业优先；",
        "jobResponsibilities2": null,
        "jobRequirements2": "熟悉C++开发语言、熟练使用QT/QML；",
        "jobResponsibilities3": null,
        "jobRequirements3": "熟悉至少一种主流数据库，有一定的SQL基础；",
        "jobResponsibilities4": null,
        "jobRequirements4": "具有阅读相关技术需求文档能力；",
        "jobResponsibilities5": null,
        "jobRequirements5": "有较强的学习能力，具有良好的表达能力、理解能力和交流能力。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "2",
        "jobTitle": "嵌入式软件开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、重庆",
        "jobResponsibilities1": "使用C/C++开发汽车控制器嵌入式软件；",
        "jobRequirements1": "统招本科及以上学历，计算机、通信、自动化等相关专业优先；",
        "jobResponsibilities2": null,
        "jobRequirements2": "熟悉C/C++语言；",
        "jobResponsibilities3": null,
        "jobRequirements3": "至少掌握一种主流编译器，如Greens、Keil、IAR、CodeWarrior等；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉AUTOSAR架构或汽车总线通信协议优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "有过复杂设备驱动芯片（如英飞凌、NXP等）的开发经验优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "能接受出差。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "3",
        "jobTitle": "整车电子电气架构工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "整车级和系统级架构文档编制，制定架构开发流程和计划；",
        "jobRequirements1": "统招本科及以上学历，通信、电气等相关专业优先；",
        "jobResponsibilities2": "CAN，LIN，CAN_FD以太网通信协议制定及数据库编辑、电气架构原理图设计；",
        "jobRequirements2": "熟悉整车电子电气功能，对平台，车型，控制器，功能等概念有清晰的认识；",
        "jobResponsibilities3": null,
        "jobRequirements3": "具备较好的沟通协调能力及材料编制能力；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉传统CAN，LIN，CAN_FD等网络通信知识优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "有PREEvision工具使用经验者优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "能接受出差。",
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "4",
        "jobTitle": "应用开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "基于现有成熟技术的产品化demo的开发；",
        "jobRequirements1": "统招全日制研究生学历，计算机、软件、电子信息等相关专业优先；",
        "jobResponsibilities2": "测试技术深耕，对于新兴的测试技术，如：模糊测试，新兴总线技术，诊断序列化技术等，实现相关技术的产品化实现，或对于原有测试技术的C或C++编码实现；",
        "jobRequirements2": "有stm32 ARM芯片的嵌入式开发、MX6U Linux相关开发经验皆可，有RTOS开发能力者优先；",
        "jobResponsibilities3": "新兴产品开发，需要实现上位机的应用软件的产品化demo编写或针对opengl等图形化技术实现GPU运算程序优化；",
        "jobRequirements3": "上位机软件开发，对数据结构有了解，熟练掌握C++，有QT开发经验；",
        "jobResponsibilities4": null,
        "jobRequirements4": "上位机软件偏图像处理，具备opengl或directX中一种开发能力，掌握cuda编程；",
        "jobResponsibilities5": null,
        "jobRequirements5": "有良好的英文阅读能力。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "5",
        "jobTitle": "自动驾驶仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京、武汉",
        "jobResponsibilities1": "负责自动驾驶系统仿真场景搭建；",
        "jobRequirements1": "统招本科及以上学历，车辆工程、电子信息、通信工程等相关专业优先；",
        "jobResponsibilities2": "负责自动驾驶HIL系统测试用例设计及调试；",
        "jobRequirements2": "有CAN总线通讯、电气原理、机械设计基础；",
        "jobResponsibilities3": null,
        "jobRequirements3": "有NI/dSpace/Vector等实时平台软硬件使用经验优先；",
        "jobResponsibilities4": null,
        "jobRequirements4": "能接受长期出差。",
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "6",
        "jobTitle": "智能电控仿真开发工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京",
        "jobResponsibilities1": "负责底盘动力域HIL系统的集成；",
        "jobRequirements1": "统招本科及以上学历，车辆工程、新能源、电子信息、通信工程等相关专业优先；",
        "jobResponsibilities2": "负责底盘动力域HIL相关模型开发；",
        "jobRequirements2": "具备良好的底盘域或动力域基础；",
        "jobResponsibilities3": null,
        "jobRequirements3": "具备一定的仿真建模能力，有过simulink、carsim等仿真建模经验者优先；",
        "jobResponsibilities4": null,
        "jobRequirements4": "了解常用总线形式，对CAN、LIN有基本的认知。",
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "7",
        "jobTitle": "智能座舱仿真测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、北京",
        "jobResponsibilities1": "配合完成智能座舱仿真环境的搭建；",
        "jobRequirements1": "统招本科及以上学历，电子、电气、自动化、通信等相关专业优先；",
        "jobResponsibilities2": null,
        "jobRequirements2": "熟悉乘用车智能座舱方案，熟悉仪表大屏、中控大屏方案的优先；",
        "jobResponsibilities3": null,
        "jobRequirements3": "对IVI系统、数字仪表、智能黑科技、T-BOX、HMI人机交互，OTA等技术了解者优先。",
        "jobResponsibilities4": null,
        "jobRequirements4": null,
        "jobResponsibilities5": null,
        "jobRequirements5": null,
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "8",
        "jobTitle": "整车电子测试工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳、武汉、重庆",
        "jobResponsibilities1": "按客户要求完成部件、系统及实车网络、诊断及用户功能测试；",
        "jobRequirements1": "统招本科及以上学历，计算机类、电子信息、通信、自动化、物理、数学等相关专业优先；",
        "jobResponsibilities2": "协助客户制定测试方案与计划，能够编写测试规范、测试用例及自动化测试脚本；",
        "jobRequirements2": "拥有汽车电子类相关社会实践或课题相关经验者优先；",
        "jobResponsibilities3": null,
        "jobRequirements3": "熟悉CAPL、C++、C#、Python等至少一种编程语言者优先；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉CAN(FD)\\LIN\\Ethernet等车载总线者优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "熟悉Vector工具，如CANoe、CANalyzer等测试工具者优先；",
        "jobResponsibilities6": null,
        "jobRequirements6": "拥有一年以上汽车驾驶经验者优先；",
        "jobResponsibilities7": null,
        "jobRequirements7": "能接受驻场出差。",
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "9",
        "jobTitle": "汽车电子测试系统工程师",
        "jobType": "研发技术",
        "jobAddress": "沈阳",
        "jobResponsibilities1": "负责汽车自动化测试系统的设计与开发（针对CAN/LIN/ETH总线协议或汽车控制器）；",
        "jobRequirements1": "统招本科及以上学历，通信、车辆工程、自动化、电子、计算机等相关专业优先；",
        "jobResponsibilities2": "测试计划编写，测试脚本开发与调试；",
        "jobRequirements2": "具备脚本编程能力，熟悉C/C++语言，了解Labview、Matlab等优先；",
        "jobResponsibilities3": null,
        "jobRequirements3": "具备良好的沟通和协调能力，良好的英语阅读及翻译能力；",
        "jobResponsibilities4": null,
        "jobRequirements4": "熟悉CAN、LIN、ETHERNET总线协议者优先；",
        "jobResponsibilities5": null,
        "jobRequirements5": "能接受出差。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }, {
        "id": "10",
        "jobTitle": "汽车电子应用工程师",
        "jobType": "研发技术",
        "jobAddress": "武汉",
        "jobResponsibilities1": "配合区域销售团队，针对Vector等代理产品开展售前交流、售后培训及日常技术支持工作并分析客户需求；",
        "jobRequirements1": "统招本科及以上学历，车辆工程、电子、控制、自动化、计算机、通信等相关专业优先；",
        "jobResponsibilities2": "解析汽车网络相关协议标准，能够使用编程语言基于Vector产品进行二次开发；",
        "jobRequirements2": "掌握Labview、C、C++、C#、Python等至少一种编程语言，具备基础英文能力；",
        "jobResponsibilities3": null,
        "jobRequirements3": "熟悉CANoe、CANdelaStudio、CANape等Vector工具及汽车相关通信协议及标准优先；",
        "jobResponsibilities4": null,
        "jobRequirements4": "具备较强的亲和力、自主学习意识、沟通协调能力及客户服务意识；",
        "jobResponsibilities5": null,
        "jobRequirements5": "能接受短期出差，出差地点主要位于珠三角、湘鄂皖等地。",
        "jobResponsibilities6": null,
        "jobRequirements6": null,
        "jobResponsibilities7": null,
        "jobRequirements7": null,
        "jobResponsibilities8": null,
        "jobRequirements8": null,
        "type": 2
      }],
      // 全部的
      detailedList: [],
      activeName: "",
      jobType: "",
      // 实习
      internshipPositionsList: [],
    }
  },
  watch: {
    searchInput(newval, oldVal) {
      if (oldVal !== newval) {
        this.searchInput = newval.replace(/\s/g, '');
      }
    }
  },
  created() {
    this.detailedList = [...this.socialRecruitmentList]
    // this.detailedList = this.mergeArraysAndEnsureUniqueId(this.campusRecruitmentList, this.socialRecruitmentList)
    this.list = [...this.detailedList]
  },
  methods: {
    // 立即申请按钮点击事件
    applyNow(type) {
      if (type == 1) {
        window.open('https://doc.weixin.qq.com/forms/AOEAXQcgAAkAd4AqQaDADgHwXKgoOqS4f', '_blank');
      } else if (type == 2) {
        window.open('https://doc.weixin.qq.com/forms/AOEAXQcgAAkAd4AqQaDADgxox78LoxSlf', '_blank');
      }
    },
    // 图标点击搜索
    selectByType(val) {
      this.list = [];
      this.jobType = val;
      this.searchInput = ''
      this.resetIconStyle()
      this.post = ''
      this.place = ''
      this.clickPlaceStyleChange('全部')
      this.clickPostStyleChange('全部')
      this.activeType = val
      if (val === 1) {
        this.list = [...this.socialRecruitmentList]
        this.socialRecruitmentStyle = 'career-type active'
      }
      if (val === 2) {
        this.list = [...this.campusRecruitmentList]
        this.campusRecruitmentStyle = 'career-type active'
      }
      if (val === 3) {
        if (this.internshipPositionsList === []) {
          this.list = []
        } else {
          this.list = [...this.internshipPositionsList]
        }
        this.internshipPositionsStyle = 'career-type active'
      }
    },
    // 重置图表样式
    resetIconStyle() {
      this.socialRecruitmentStyle = 'career-type'
      this.campusRecruitmentStyle = 'career-type'
      this.internshipPositionsStyle = 'career-type'
    },
    // 搜索
    search() {
      this.list = [];
      this.clickPlaceStyleChange("全部")
      this.clickPostStyleChange("全部")
      if (this.jobType === 1) {
        this.socialRecruitmentList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      } else if (this.jobType === 2) {
        this.campusRecruitmentList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      } else if (this.jobType === 3) {
        this.internshipPositionsList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      } else {
        this.detailedList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      }
    },
    // 地点
    searchPlace(val) {
      this.list = [];
      this.clickPlaceStyleChange(val)
      if (val === '全部') {
        this.place = ''
        if (this.post === '' && this.jobType === '') {
          this.list = this.detailedList
        } else {
          if (this.post === '' && this.jobType !== '') {
            this.selectByType(this.jobType)
          } else {
            this.socialRecruitmentList.filter(item => {
              if (item.jobType === this.post) {
                this.list.push(item)
              }
            })
          }
        }
      } else {
        this.place = val
        if (this.post !== '' && this.jobType !== '') {
          if (this.jobType === 1) {
            this.socialRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 2) {
            this.campusRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 3) {
            this.internshipPositionsList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
        }
        if (this.post === '' && this.jobType !== '') {
          if (this.jobType === 1) {
            this.socialRecruitmentList.filter(item => {
              if (item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 2) {
            this.campusRecruitmentList.filter(item => {
              if (item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 3) {
            this.internshipPositionsList.filter(item => {
              if (item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
        }
        if (this.post !== '' && this.jobType === '') {
          this.detailedList.filter(item => {
            if (item.jobType === this.post && item.jobAddress.includes(this.place)) {
              this.list.push(item)
            }
          });
        }
        if (this.post === '' && this.jobType === '') {
          this.detailedList.filter(item => {
            if (item.jobAddress.includes(val)) {
              this.list.push(item)
            }
          });
        }
      }
      const lastList = this.list
      if (this.searchInput !== null && this.searchInput !== '') {
        this.list = []
        lastList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      }
    },
    // 职位
    searchPost(val) {
      this.list = [];
      this.clickPostStyleChange(val)
      if (val === '全部') {
        this.post = ''
        if (this.place === '' && this.jobType === '') {
          this.list = this.detailedList
        } else {
          if (this.place === '' && this.jobType !== '') {
            this.selectByType(this.jobType)
          } else {
            this.socialRecruitmentList.filter(item => {
              if (item.jobAddress.includes(this.place)) {
                this.list.push(item)
              }
            })
          }
        }
      } else {
        this.post = val
        if (this.place !== '' && this.jobType !== '') {
          if (this.jobType === 1) {
            this.socialRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 2) {
            this.campusRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 3) {
            this.internshipPositionsList.filter(item => {
              if (item.jobType === this.post && item.jobAddress.includes(this.place) && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
        }
        if (this.place === '' && this.jobType !== '') {
          if (this.jobType === 1) {
            this.socialRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 2) {
            this.campusRecruitmentList.filter(item => {
              if (item.jobType === this.post && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
          if (this.jobType === 3) {
            this.internshipPositionsList.filter(item => {
              if (item.jobType === this.post && item.type === this.jobType) {
                this.list.push(item)
              }
            })
          }
        }
        if (this.place !== '' && this.jobType === '') {
          this.detailedList.filter(item => {
            if (item.jobType === this.post && item.jobAddress.includes(this.place)) {
              this.list.push(item)
            }
          });
        }
        if (this.place === '' && this.jobType === '') {
          this.detailedList.filter(item => {
            if (item.jobType === val) {
              this.list.push(item)
            }
          });
        }
      }
      const lastList = this.list
      if (this.searchInput !== null && this.searchInput !== '') {
        this.list = []
        lastList.filter(item => {
          if (item.jobTitle.includes(this.searchInput)) {
            this.list.push(item)
          }
        });
      }
    },
    mergeArraysAndEnsureUniqueId(array1, array2) {
      const mergedArray = [...array1, ...array2];
      var num = 1;
      const uniqueMergedArray = mergedArray.map(item => {
        const newId = num;
        num++;
        return {...item, id: newId};
      });
      return uniqueMergedArray;
    },
    clickPlaceStyleChange(val) {
      this.resetPlaceStyle()
      switch (val) {
        case '沈阳':
          this.shenYangStyle = 'content specific special'
          break;
        case '北京':
          this.beiJingStyle = 'content specific special'
          break;
        case '上海':
          this.shangHaiStyle = 'content specific special'
          break;
        case '广州':
          this.guangZhoStyle = 'content specific special'
          break;
        case '重庆':
          this.chongQingStyle = 'content specific special'
          break;
        case '武汉':
          this.wuHanStyle = 'content specific special'
          break;
        case '天津':
          this.tianJinStyle = 'content specific special'
          break;
        case '全部':
          this.wholeStyle = 'content specific special'
          break;
      }
    },
    clickPostStyleChange(val) {
      this.resetPostStyle()
      switch (val) {
        case '研发技术':
          this.technologyStyle = 'content specific type-of special'
          break;
        case '市场销售':
          this.marketingStyle = 'content specific type-of special'
          break;
        case '专业职能':
          this.professionalFunctionsStyle = 'content specific type-of special'
          break;
        case '全部':
          this.wholeStyle1 = 'content specific special'
          break;
      }
    },
    resetPlaceStyle() {
      this.shenYangStyle = 'content specific'
      this.beiJingStyle = 'content specific'
      this.shangHaiStyle = 'content specific'
      this.wuHanStyle = 'content specific'
      this.tianJinStyle = 'content specific'
      this.guangZhoStyle = 'content specific'
      this.chongQingStyle = 'content specific'
      this.wholeStyle = 'content specific'
    },
    resetPostStyle() {
      this.wholeStyle1 = 'content specific'
      this.technologyStyle = 'content specific type-of'
      this.marketingStyle = 'content specific type-of'
      this.professionalFunctionsStyle = 'content specific type-of'
    }
  },
  mounted() {
    document.title = "加入我们 - 东信创智"
    new this.$wow.WOW().init()
  }
}
</script>

<style scoped>

</style>
